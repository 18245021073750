import React from "react"
import { graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlogIndexQuery } from '../../types/graphql-types'

interface IBlogIndexProps {
  data: BlogIndexQuery;
  location: Location;
}


const PrivacyPolicy: React.FC<IBlogIndexProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const siteDescription = data.site.siteMetadata.description

  return (
    <Layout location={location} title={siteTitle} description={siteDescription}>
      <h2>
        個人情報について
      </h2>
      <div>
        当サイトではユーザーの個人情報を取得することはありません。
      </div>
      <h2>
      掲載されている広告について
      </h2>
      <div>
      当ブログサイトでは、Googleアドセンスを利用しています。
      </div>
      <h2>
        アクセス解析ツールについて
      </h2>
      <div>
      当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。
この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。
      </div>
      <h2>
        アフィリエイトについて
      </h2>
      <div>
      当サイトは、Amazonアソシエイト・プログラムには参加しておらず、ページ内のAmazonリンクはアフィリエイトリンクではありません。
      </div>
      <Bio />
    </Layout>
  )
}

export default PrivacyPolicy

export const pageQuery = graphql`
  query PrivacyPolicy {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
